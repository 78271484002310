import React from "react";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../../assests/images/hero-img1.jpg";
import "./hero-section.css";
import Slider from "react-slick";

const HeroSection = () => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="hero__section">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content">
              <h2 className="mb-4 hero__title">
              Biznesin avtomatlaşdırılması <br /> sahəsində etibarlı tərəfdaşınız  
              </h2>
              <p className="mb-5">
              POSsistem Group müasir avtomatlaşdırma texnologiyalarının <br />   köməyi ilə biznesin daha səmərəli və gəlirli olmasına <br />  kömək edən şirkətdir
              </p>
            </div>
            <div className="search">
              <input type="text" placeholder="Axtar" />
              <button className="btn">Axtar</button>
            </div>
          </Col>

          {/* <Col lg="6" md="6">
          <Slider {...settings}>
      <div>
        <img src="./p-88.jpg"></img>
      </div>
      <div>
      <img src="./p-99.jpg"></img>
      </div>
      <div>
      <img src="./p-100.jpg"></img>
      </div>
      <div>
      <img src="./p-111.jpg"></img>
      </div>
      <div>
      <img src="./p-122.jpg"></img>
      </div>
      <div>
      <img src="./p-133.jpg"></img>
      </div>
    </Slider>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
