import React from "react"
import img from "../../assests/images/about-us.jpg"
import Back from "../Back"
import Courses2 from "../../components/Courses-section/Courses2"

const Sektorlar = () => {
  return (
    <>
      <section className='services mb'>
        <Back name='Həllər' title='Həllər - Bütün xidmətlər' cover={img} />
        <div className='featured container'>
          <Courses2/>
        </div>
      </section>
    </>
  )
}

export default Sektorlar

