import React, { useEffect, useRef, useState } from "react";
import { Container } from "reactstrap";
import "./header.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IoCall } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";

const navLinks = [
  {
    display: "Əsas",
    url: "/",
  },
  {
    display: "Haqqımızda",
    url: "/about",
  },
  {
    display: "Tərəfdaşlarımız",
    url: "/partners",
  },
  {
    display: "Əlaqə",
    url: "/contact",
  },
  {
    display: "Proqram təminatı",
    url: "/proqram",
  },

];

const Header = () => {
  const menuRef = useRef();

  const menuToggle = (event) => {
    event.stopPropagation(); // Tıklamanın menü öğelerine yayılmasını durdurun
    menuRef.current.classList.toggle("active__menu");
  };

  const [navList, setNavList] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    // Dışarıya tıklandığında dropdown'u kapat
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
      if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
        setDropdownOpen2(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        menuRef.current.classList.remove("active__menu");
      }
    };

    // Etkinlik dinleyicisini ekleyin
    document.addEventListener("mousedown", handleClickOutside);

    // Temizlik: bileşen kaldırıldığında etkinlik dinleyicisini kaldırın
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleDropdown2 = () => {
    setDropdownOpen2(!dropdownOpen2);
  };

  const closeNavMenu = () => { 
    setNavList(false);
  };

  return (
    <header className="header">
      <Container>
        <div className="navigation d-flex align-items-center justify-content-between">
          <div className="logo">
            <h2 className=" d-flex align-items-center gap-1">
              <i className="ri-pantone-line"></i> PosGroup.
            </h2>
          </div>

          <div className="nav d-flex align-items-center gap-5">
            <div className="nav__menu" ref={menuRef}>
              <ul className="nav__list" onClick={(e) => e.stopPropagation()}>
                {navLinks.map((item, index) => (
                  <li key={index} className="nav__item">
                    <a href={item.url}>{item.display}</a>
                  </li>
                ))}
                <li className="nav__item" style={{ display: 'flex', justifyContent: 'left' }}>
                  <Link to="/avadanliqlar">
                    Avadanlıqlar
                  </Link>
                  <div
                    ref={dropdownRef2}
                    className="dropdown"
                    style={{ float: "right" }}
                  >
                    <div
                      onClick={toggleDropdown2}
                      style={{ cursor: "pointer", position: "relative", top: "4px", left: "3px" }}
                    >
                      {dropdownOpen2 ? <FiChevronUp /> : <FiChevronDown />}
                    </div>
                    {dropdownOpen2 && (
                      <div className="dropdown-content">
                        <a href="/sensor-monobloklar" onClick={closeNavMenu}>Sensor monobloklar</a>
                        <a href="/tereziler" onClick={closeNavMenu}>Tərəzilər</a>
                        <a href="/cek-printer" onClick={closeNavMenu}>Çek printerlər</a>
                        <a href="/barkod-printer" onClick={closeNavMenu}>Barkod printerlər</a>
                        <a href="/barkod-oxuyucu" onClick={closeNavMenu}>Barkod oxuyucular</a>
                        <a href="/el-terminali" onClick={closeNavMenu}>Əl terminalı</a>
                        <a href="/pul-qutulari" onClick={closeNavMenu}>Pul qutuları</a>
                        <a href="/etiket" onClick={closeNavMenu}>Etiket kağızları</a>
                        <a href="/fiyatgor" onClick={closeNavMenu}>Fiyatgör</a>
                        <a href="/cardreader" onClick={closeNavMenu}>Kart oxuyucular</a>
                      </div>
                    )}
                  </div>
                </li>
                <li className="nav__item" style={{ display: 'flex', justifyContent: 'left' }}>
                  <Link to="/sektorlar">
                    Həllər
                  </Link>
                  <div
                    ref={dropdownRef}
                    className="dropdown"
                    style={{ float: "right" }}
                  >
                    <div
                      onClick={toggleDropdown}
                      style={{ cursor: "pointer", position: "relative", top: "4px", left: "3px" }}
                    >
                      {dropdownOpen ? <FiChevronUp /> : <FiChevronDown />}
                    </div>
                    {dropdownOpen && (
                      <div className="dropdown-content">
                        <a href="/market" onClick={closeNavMenu}>ERP Market</a>
                        <a href="/restoran" onClick={closeNavMenu}>ERP Restoran</a>
                        <a href="/sahe-satis" onClick={closeNavMenu}>ERP Sahə satış</a>
                        <a href="/geyim" onClick={closeNavMenu}>ERP Geyim</a>
                        <a href="/istehsalat" onClick={closeNavMenu}>ERP İstehsalat</a>
                        <a href="/yanacaqdoldurma" onClick={closeNavMenu}>ERP Yanacaqdoldurma</a>
                        <a href="/aptek" onClick={closeNavMenu}>ERP Aptek</a>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>

            <div className="nav__right">
              <p className="mb-0 d-flex align-items-center gap-2">
                <i className="ri-phone-line"></i> +994 55 211 10 68
              </p>
            </div>
            <div className="button btncall">
                  <a href="tel:+994552111068">
                    {" "}
                    <button style={{ backgroundColor: '#17BF9E', borderRadius:'5px' }} className="btn1">
                      <IoCall size={20} />
                    </button>
                  </a>
                </div>
                <div className="button btnwp">
                  <a href="https://api.whatsapp.com/send?phone=994552111068&text=">
                    {" "}
                    <button style={{ backgroundColor: '#25D366', borderRadius:'5px' }} className="btn11">
                      {/* <LuMessageSquare size={20} /> */}
                      <FaWhatsapp size={20} />
                    </button>
                  </a>
                </div>
          </div>

          <div className="mobile__menu">
            <span>
              <i className="ri-menu-line" onClick={menuToggle}></i>
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;

