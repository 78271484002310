import React from "react"
import Back from "../Back"
import RecentCard from "../recent/RecentCard"
import "../recent/recent.css"
import img from "../../assests/images/about-us.jpg"

const Avadanliqlar = () => {
  return (
    <>
      <section className='blog-out mb'>
        <Back name='Avadanlıqlar' title='Avadanlıqlar' cover={img} />
        <div className='container recent'>
          <RecentCard />
        </div>
      </section>
    </>
  )
}

export default Avadanliqlar
