import React, { useEffect, useState } from "react"
import Back from "../../Back"
import Heading from "../../Heading"
import RecentCard from "../../recent/RecentCard"
import img from "../../../assests/images/about-us.jpg"
import "../../recent/recent.css"
import axios from "axios"

const Komputerler = () => {
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        "https://www.pos-sistem.az/possistem/dashboard.php/", {
          withCredentials: false
      }
      );
      setItems(response.data.filter(item => item.category === "Etiket kağızları"));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
       <section className='blog-out mb'>
        <Back name='Etiket kağızları' title='Avadanlıqlar-Etiket kağızları' cover={img} />
        <div className='container recent'>
        <div className='content grid3 mtop'>
        {list.map((val, index) => {
          const { image,category, name } = val
          return (
            <div className='box shadow' key={index}>
              <div className='img'>
                <img src={`data:image/jpeg;base64,${image}`} alt='' />
              </div>
              <div className='text'>
                <div className='category flex'>
                  <span style={{ background: category === "For Sale" ? "#25b5791a" : "#ff98001a", color: category === "For Sale" ? "#25b579" : "#ff9800" }}>{category}</span>
                  <i className='fa fa-heart'></i>
                </div>
                <h4>{name}</h4>
                <p>
                  <i className='fa fa-location-dot'></i> Bakı, Azərbaycan
                </p>
              </div>
            </div>
          )
        })}
      </div>
        </div>
      </section>
    </>
  )
}

export default Komputerler
