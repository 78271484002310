import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function ListUser() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  function handleChangeImage(event) {
    if (event.target.name === "image") {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          const base64WithoutHeader = base64String.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          );
          setInputs((values) => ({
            ...values,
            image: base64WithoutHeader,
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();

    axios.post("https://www.pos-sistem.az/possistem/dashboard.php/save", inputs)
      .then(function (response) {
        navigate("/dashboard");
      });
  };

  return (
    <div>
      <h1>Cihaz əlavə et</h1>
      <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
          <tbody>
            <tr>
              <th>
                <label>Ad: </label>
              </th>
              <td>
                <input type="text" name="name" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Kateqoriya: </label>
              </th>
              <td>
                <input type="text" name="category" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Şəkil: </label>
              </th>
              <td>
                <input type="file" name="image" onChange={handleChangeImage} />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <button>Yadda saxla</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}
