import React from "react";
import "./about.css";
import { Container, Row, Col } from "reactstrap";
import aboutImg from "../../assests/images/about-us.jpg";
import CountUp from "react-countup";
import "./about.css";
import Slider from "react-slick";

const AboutUs = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
          <Slider {...settings}>
      <div>
        <img src="./p-88.jpg"></img>
      </div>
      <div>
      <img src="./p-99.jpg"></img>
      </div>
      <div>
      <img src="./p-100.jpg"></img>
      </div>
      <div>
      <img src="./p-111.jpg"></img>
      </div>
      <div>
      <img src="./p-122.jpg"></img>
      </div>
      <div>
      <img src="./p-133.jpg"></img>
      </div>
    </Slider>
          </Col>

          <Col lg="6" md="6">
            <div className="about__content">
              <h2>Haqqımızda</h2>
              <p>
              Biz Possistem Group MMC şirkəti olaraq 2014 cü ildən rəsmi fəaliyyət göstəririk. Şirkətimiz bir çox xarici ölkərəndən barkod avadanlıqlarının idxalını və satışını təşkil edir. Bununla yanaşı şirkətimizdə sistem avtomatlaşdırılması üçün Türkiyənin "ERP12" proqram təminatı  mövcuddur.  Beləki müxtəlif tipli ticarət sahələri (mağaza,market,geyim mağası,aptek,restoran,sahə satış,istehsalat,yanacaqdoldurma və s.) üzrə lisenziyalı proqram təminatının tətbiqini həyata keçiririk.
              </p>

              <div className="about__counter"> 
                <div className=" d-flex gap-5 align-items-center">
                  <div className="single__counter">
                    <span className="counter">
                      <CountUp start={0} end={25} duration={2} suffix="K" />
                    </span>

                    <p className="counter__title">Tamamlanmış layihələr</p>
                  </div>

                  <div className="single__counter">
                    <span className="counter">
                      <CountUp start={0} end={12} duration={2} suffix="M" />
                    </span>

                    <p className="counter__title">İstifadə edən müştərilər</p>
                  </div>
                </div>

                <div className=" d-flex gap-5 align-items-center">
                  <div className="single__counter">
                    <span className="counter">
                      <CountUp start={0} end={95} duration={2} suffix="M" />
                    </span>

                    <p className="counter__title">İstifadə edən müştərilər</p>
                  </div>

                  <div className="single__counter">
                    <span className="counter">
                      <CountUp start={0} end={5} duration={2} suffix="K" />
                    </span>

                    <p className="counter__title">Tamamlanmış layihələr</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
