import React from "react"
import Header from "../components/Header/Header"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./Home"
import About from "./about/About"
import Footer from "../components/Footer/Footer"
import Partners from "./partners/Partners"
import Contact from "./contact/Contact"
import Avadanliqlar from "./avadanliqlar/Avadanliqlar"
import Heller from "./sektorlar/Heller"
import Market from "../pages/Extra/market/Market"
import Oyuncaq from "../pages/Extra/oyuncaq/Oyuncaq"
import Telefon from "../pages/Extra/telefon/Telefon"
import Restoran from "../pages/Extra/restoran/Restoran"
import Aptek from "../pages/Extra/aptek/Aptek"
import KofeShop from "../pages/Extra/kofeshop/KofeShop"
import Kafe from "../pages/Extra/kafe/Kafe"
import Geyim from "../pages/Extra/geyim/Geyim"
import Kiosk from "../pages/Extra/kiosk/Kiosk"
import Avto from "../pages/Extra/avto/Avto"
import Xirdavat from "../pages/Extra/xirdavat/Xirdavat"
import Zoo from "../pages/Extra/zoo/Zoo"
import Parfumeriya from "../pages/Extra/parfumeriya/Parfumeriya"
import Optik from "../pages/Extra/optik/Optik"
import BarkodOxuyucu from "../pages/Extra2/barkod-oxuyucu/Aptek"
import BarkodPrinter from "../pages/Extra2/barkod-printer/Avto"
import BarkodTerezi from "../pages/Extra2/barkod-terezi/Kafe"
import Touchscreen from "../pages/Extra2/touchscreen/Geyim"
import PulQutulari from "../pages/Extra2/pulqutu/Kiosk"
import Fiyatgor from "../pages/Extra2/fiyatgor/KofeShop"
import Komputerler from "../pages/Extra2/komputerler/Market"
import Kagizlar from "../pages/Extra2/kagizlar/Optik"
import CardReader from "../pages/Extra2/cardreader/Oyuncaq"
import CekPrinter from "../pages/Extra2/touchscreen copy/CekPrinter"
import AdminPanel from '../pages/AdminPanel/AdminPanel'
import Protected from '../pages/Protected/Protected'
import CreateUser from '../pages/CreateUser/CreateUser'
import EditUser from '../pages/EditUser/EditUser'
import Dashboard from '../pages/Dashboard/Dashboard'

const Pages = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/partners' element={<Partners />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/avadanliqlar' element={<Avadanliqlar />} />
          <Route path='/sektorlar' element={<Heller />} />
          <Route path='/market' element={<Market />} />
          <Route path='/oyuncaq' element={<Oyuncaq />} />
          <Route path='/yanacaqdoldurma' element={<Telefon />} />
          <Route path='/aptek' element={<Aptek />} />
          <Route path='/geyim' element={<KofeShop />} />
          <Route path='/restoran' element={<Restoran />} />
          <Route path='/sahe-satis' element={<Kafe />} />
          {/* <Route path='/geyim' element={<Geyim />} /> */}
          <Route path='/kiosk' element={<Kiosk />} />
          <Route path='/zoo' element={<Zoo />} />
          <Route path='/parfumeriya' element={<Parfumeriya />} />
          <Route path='/optik' element={<Optik />} />
          <Route path='/avto' element={<Avto />} />
          <Route path='/istehsalat' element={<Xirdavat />} />
          <Route path='/sensor-monobloklar' element={<BarkodOxuyucu />} />
          <Route path='/tereziler' element={<BarkodPrinter />} />
          <Route path='/cek-printer' element={<CekPrinter />} />
          <Route path='/barkod-printer' element={<BarkodTerezi />} />
          <Route path='/barkod-oxuyucu' element={<Touchscreen />} />
          <Route path='/el-terminali' element={<PulQutulari />} />
          <Route path='/pul-qutulari' element={<Fiyatgor />} />
          <Route path='/etiket' element={<Komputerler />} />
          <Route path='/fiyatgor' element={<Kagizlar />} />
          <Route path='/cardreader' element={<CardReader />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
      <Route path="dashboard/create"  element={<Protected Component={CreateUser} />} />
      <Route path="dashboard/:id/edit"  element={<Protected Component={EditUser} />} />
        </Routes>
        <Footer />
      </Router>
    </>
  )
}

export default Pages
