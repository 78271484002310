import React from "react"
import Back from "../../Back"
import Heading from "../../Heading"
import img from "../../../assests/images/about-us.jpg"
import "./xirdavat.css"

const Xirdavat = () => {
  return (
    <>
      <section className='market'>
        <Back name='ERP İstehsalat' title='Sektorlar - ERP İstehsalat' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='ERP İstehsalat' subtitle='Haqqımızda daha çox oxuyun' />

            <p>Biz Possistem Group MMC şirkəti olaraq 2014 cü ildən rəsmi fəaliyyət göstəririk. Şirkətimiz bir çox xarici ölkərəndən barkod avadanlıqlarının idxalını və satışını təşkil edir. Bununla yanaşı şirkətimizdə sistem avtomatlaşdırılması üçün Türkiyənin "ERP12" proqram təminatı  mövcuddur.  Beləki müxtəlif tipli ticarət sahələri (mağaza,market,geyim mağası,aptek,restoran,sahə satış,istehsalat,yanacaqdoldurma və s.) üzrə lisenziyalı proqram təminatının tətbiqini həyata keçiririk.
            </p>
            <a href="/about"><button className='btn2'>Haqqımızda daha çox</button></a>
          </div>
          <div className='right row'>
            <img src={img} alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default Xirdavat
